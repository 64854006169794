<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
      <li class="inline-flex items-center">
        <router-link
          :to="{ name: 'SubscriptionPlans' }"
          class="inline-flex items-center text-sm font-medium text-brand-primary dark:text-gray-400 dark:hover:text-white"
        >
          Plans
        </router-link>
      </li>
      <li aria-current="page">
        <div class="flex items-center">
          <svg class="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
          </svg>
          <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">{{ subscription.name }}</span>
        </div>
      </li>
    </ol>
  </nav>
  <div class="md:grid grid-cols-2 gap-10 py-6">
    <div>
      <processor-card
        v-for="item in processors"
        :key="item.name"
        :processor="item"
        :selected="selected"
        @update-selected-processor="updateProcessorChoice"
      />
    </div>
    <div class="px-0 md:px-24">
      <subscription-card :subscription="subscription" :appFeatures="features" :show-features="true" :rate="conversionRate" />

      <div class="text-center mt-4">
        <label for="" class="font-manrope text-sm font-bold mb-2 inline-block">Duration <small>(months)</small></label>
        <div class="flex items-center justify-center gap-x-2">
          <button class="w-12 text-brand-primary bg-red-200 text-2xl py-1 rounded-lg" @click="numMonths = numMonths <= 2 ? 1 : numMonths - 1">
            -
          </button>
          <input
            type="text"
            :value="numMonths"
            class="w-12 border-light-grey rounded-lg disabled text-center focus:ring-border-light-grey font-manrope"
          />
          <button class="w-12 text-green-600 bg-green-200 text-2xl py-1 rounded-lg" @click="numMonths += 1">+</button>
        </div>
      </div>

      <div class="flex justify-center items-center mt-4">
        <tam-button
          @click="initPayment"
          :disabled="!selected"
          type="submit"
          class="bg-brand-black text-white rounded-lg px-4 text-[14px] hover:bg-brand-black"
          label="Make payment"
        />
      </div>
    </div>
  </div>

  <Dialog v-if="proceedWithPayment" class="backdrop-blur-sm">
    <paystack-gateway
      :payload="payload"
      @close="closePayment"
      @failed-payment="failedPayment"
      @success-payment="successPayment"
      @cancel="proceedWithPayment = false"
      v-if="selected === 'paystack'"
    />
    <stripe-gateway
      :payload="payload"
      :amount="(subscription.amount * numMonths).toFixed(2)"
      @close="closePayment"
      @failed-payment="failedPayment"
      @success-payment="successPayment"
      @cancel="proceedWithPayment = false"
      v-if="selected === 'stripe' && payload?.email"
    />
  </Dialog>

  <loading-overlay v-if="loading" />
</template>

<script setup>
import { Stripe, Payoneer, Paypal, Paystack } from '@/icons';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { errorMessage, alert, formatNumber } from '@/utils/helper';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import ProcessorCard from '@/components/subscriptions/PaymentProcessorCard.vue';
import SubscriptionCard from '@/components/subscriptions/SubscriptionCard.vue';
import PaystackGateway from '@/components/payment-gateways/PaystackGateway.vue';
import StripeGateway from '@/components/payment-gateways/StripeGateway.vue';

import Dialog from '@/components/Dialog.vue';
import TamButton from '@/components/TamButton.vue';

const route = useRoute();
const router = useRouter();
const store = useStore();
const loading = ref(false);
const selected = ref('');
const payload = ref({});
const proceedWithPayment = ref(false);
const features = ref([]);
const conversionRate = ref(1);
const nairaRate = ref(1);
const numMonths = ref(1);

const processors = computed(() => {
  try {
    const paymentProcessors = [
      {
        name: 'stripe',
        icon: Stripe,
      },
      {
        name: 'paystack',
        icon: Paystack,
      },
      {
        name: 'paypal',
        icon: Paypal,
      },
      {
        name: 'payoneer',
        icon: Payoneer,
      },
    ];
    const gateways = store.getters['global/getPaymentProcessors'];
    return paymentProcessors.reduce((pv, cv) => {
      const gateway = gateways[cv.name.toLowerCase()];
      if (gateway?.uuid !== undefined) {
        pv.push({ ...cv, ...gateway });
      }
      return pv;
    }, []);
  } catch (error) {
    console.log(error);
  }
});

const subscription = computed(() => {
  const subPlan = store.getters['global/getPlans'].find((item) => item.uuid === route.params.id) || {};

  if (!subPlan?.uuid) {
    alert('Subscription plan not found', 'error');
    router.push({ name: 'SubscriptionPlans' });
  }

  return subPlan;
});

const user = computed(() => {
  return store.getters['auth/userDetails'];
});

const setLoading = (value) => {
  loading.value = value;
};

const setPaystack = (reference) => {
  const { public_key } = processors.value.find((processor) => processor.name === selected.value);
  const amount = (
    subscription.value.amount *
    100 *
    (nairaRate.value == 1 ? conversionRate.value : nairaRate.value) *
    parseInt(numMonths.value)
  ).toFixed(2);
  const { email, name, id } = store.getters['auth/userDetails'];
  const metadata = { user_id: id, plan_id: subscription.value.uuid, init_time: Number(new Date().valueOf() / 1000).toFixed(0) };
  payload.value = {
    reference,
    public_key,
    email,
    amount,
    firstname: name.split(' ')[0],
    metadata,
    currency: 'NGN',
    quantity: parseInt(numMonths.value),
  };
};

const setStripe = (client_secret) => {
  const { email } = store.getters['auth/userDetails'];
  const { public_key } = processors.value.find((processor) => processor.name === selected.value);
  payload.value = { client_secret, public_key, email };
};

const setPaypal = () => {};

const setPayoneer = () => {};

const setPayload = (result) => {
  switch (selected.value) {
    case 'paystack':
      setPaystack(result);
      proceedWithPayment.value = true;
      break;
    case 'stripe':
      setStripe(result);
      proceedWithPayment.value = true;
      break;

    default:
      break;
  }
};

const failedPayment = (error) => {
  try {
    process.env.NODE_ENV === 'development' ? console.log(error) : '';
    proceedWithPayment.value = false;
    alert(error?.message, 'error');
    store.dispatch('global/updatePayment', {
      payment_gateway_type: selected.value,
      plan: { subscription_plan_id: subscription.value.uuid },
      status: error.status,
      payment_reference: payload.value.reference,
    });
    selected.value = '';
  } catch (error) {
    alert(errorMessage(error), 'error');
  }
};

const successPayment = (ref) => {
  try {
    proceedWithPayment.value = false;
    alert('Payment successful', 'success');
    store.dispatch('global/updatePayment', {
      payment_gateway_type: selected.value,
      plan: { subscription_plan_id: subscription.value.uuid },
      status: 'completed',
      payment_reference: payload.value.reference,
    });
    selected.value = '';
  } catch (error) {
    alert(errorMessage(error), 'error');
  }
};

const closePayment = () => {
  process.env.NODE_ENV === 'development' ? console.log('closed') : '';
};

const updateProcessorChoice = async (processor) => {
  if (processor == 'paystack') {
    try {
      loading.value = true;
      const res = await store.dispatch('global/getConversionRate');
      if (res?.value > 0) {
        nairaRate.value = res.value;
      }
    } catch (error) {
    } finally {
      loading.value = false;
    }
  } else {
    nairaRate.value = 1;
  }
  selected.value = processor;
  console.log(nairaRate.value);
};

const initPayment = async () => {
  setLoading(true);
  const rate = selected.value == 'stripe' ? 1 : conversionRate.value;
  try {
    const result = await store.dispatch('global/makePayment', {
      processor: selected.value,
      plan: {
        subscription_plan_id: subscription.value.uuid,
        duration: parseInt(numMonths.value),
        amount: (subscription.value.amount * (nairaRate.value == 1 ? rate : nairaRate.value) * parseInt(numMonths.value)).toFixed(2),
      },
    });
    setPayload(result);
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    setLoading(false);
  }
};

const getSubscriptions = async () => {
  setLoading(true);
  try {
    await store.dispatch('global/getSubscriptionPlans');
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    setLoading(false);
  }
};

const getPaymentProcessors = async () => {
  setLoading(true);
  try {
    await store.dispatch('global/getPaymentProcessors');
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    setLoading(false);
  }
};

const getFeatures = async () => {
  setLoading(true);
  try {
    features.value = await store.dispatch('global/getFeatures');
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    setLoading(false);
  }
};

const getCurrencyRate = async () => {
  let conversionCurrency = localStorage.getItem('currency');
  if (conversionCurrency == 'USD') {
    conversionRate.value = 1;
    return;
  }
  try {
    loading.value = true;
    const res = await store.dispatch('global/getConversionRate');
    if (res?.value > 0) {
      conversionRate.value = res.value;
    }
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await Promise.all([getPaymentProcessors(), getSubscriptions(), getCurrencyRate()]);
});
</script>

<template>
  <div
    class="bg-[#e7e2e2] border m-0 rounded-xl p-8 w-full mb-1 md:mb-2 flex"
    style="background-color: rgba(231, 226, 226, 0.3)"
  >
    <div
      class="flex flex-col gap-[9px] items-start justify-start shrink-1 relative w-11/12 mr-6"
    >
      <img
        :src="processor.icon"
        class="shrink-0 relative overflow-visible"
        style=""
        width="69"
        height="29"
      />

      <div
        class="text-[rgba(0,0,0,0.70)] text-left relative flex items-center justify-start w-full"
        style="font: 400 13px/135% 'Albert Sans', sans-serif"
      >
        <div>Pay with {{ processor.name?.toUpperCase() }}</div>
      </div>
    </div>
    <div class="w-auto">
      <div
        class="shrink-1 w-10 h-6 flex self-end relative inset-y-0 cursor-pointer top-0"
      >
        <input
            type="radio"
            name="processor"
            @change="update"
            class=" relative inline-flex items-center cursor-pointer top-1"
          />
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  processor: { type: Object, default: () => ({ uuid: '' }) },
  selected: { type: String, default: "" },
});

const emit = defineEmits(["update-selected-processor"]);

const update = () => {
  const value = props.processor.name?.toLowerCase();
  emit("update-selected-processor", value);
};
</script>

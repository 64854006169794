<template>
  <div class="my-9 p-4 md:p-9 bg-white rounded w-full">
    <div class="flex flex-col justify-between items-center">
      <div class="bg-brand-primary rounded-full p-3">
        <img :src="DangerAlert" alt="alert" width="37" height="37" />
      </div>
      <div class="pt-4 flex flex-col justify-between items-center">
        <h1 class="font-cooper font-medium text-3xl text-[#090E11] text-center">Notice!</h1>
        <span class="font-GTWalsheimPro font-normal text-sm text-[#090E11]/80 text-center">Proceed with payment confirmation</span>
      </div>
      <div class="pt-4">
        <button
          class="bg-brand-primary text-white p-4 rounded-lg mr-3"
          @click.prevent="payWithPaystack"
        >
        Confirm payment
        </button>
        <button
          data-modal-hide="popup-modal"
          type="button"
          @click.prevent="emit('cancel')"
          class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-4 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
        >
          No, cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, defineEmits } from 'vue';
import { DangerAlert } from '@/icons.js';

const scriptLoaded = ref(null);

const props = defineProps({
  payload: { type: Object, default: () => ({ public_key: '', email: '', amount: 0, reference: '', firstname: '', metadata: {} }) },
});

const paystackOptions = ref({});

const emit = defineEmits(['cancel', 'failed-payment', 'success-payment']);

const loadScript = callback => {
  const script = document.createElement('script');
  script.src = 'https://js.paystack.co/v2/inline.js';

  document.getElementsByTagName('head')[0].appendChild(script);
  if (script.readyState) {
    // IE
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    // Others
    script.onload = () => {
      callback();
    };
  }
};

const onSuccess = data => {
  process.env.NODE_ENV === 'development' ? console.log(data) : '';
  emit('success-payment', data);
};

const onClose = data => {
  process.env.NODE_ENV === 'development' ? console.log(data) : '';
  const error = data ? data : { message: 'cancelled transaction', 'status': 'cancelled' };
  emit('failed-payment', error);
};

let payWithPaystack = () => {
  scriptLoaded.value &&
    scriptLoaded.value.then(() => {
      const handler = window.PaystackPop.setup(paystackOptions.value);
      handler.openIframe();
    });
};

onBeforeMount(() => {
  if (scriptLoaded.value) return;
  scriptLoaded.value = new Promise(resolve => {
    loadScript(() => {
      resolve();
    });
  });
});

onMounted(() => {
  paystackOptions.value = {
    key: props.payload.public_key,
    email: props.payload.email,
    firstname: props.payload.firstname,
    amount: props.payload.amount,
    ref: props.payload.reference,
    callback: (response) => onSuccess(response),
    onClose: () => onClose({ message: 'cancelled transaction' }),
    onBankTransferConfirmationPending: () => onClose({ message: 'Closed Checkout' }),
    metadata: props.payload.metadata,
    currency: props.payload.currency,
    quantity: props.payload.quantity,
  };
});
</script>

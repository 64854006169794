<template>
  <div class="relative inset-0 lg:w-full flex flex-col h-full">
    <div
      class="sticky top-0 z-10 rounded-b-xl py-5 lg:px-32 bg-brand-side w-full flex justify-between flex-none"
    >
      <div @click="$router.go(-1)" class="pl-4">
        <icon
          :path="icon.mdiArrowLeftThin"
          size="24"
          class="text-white cursor-pointer fill-current flex-none"
        />
      </div>
      <p class="text-white text-center grow whitespace-nowrap">Edit Job Post</p>
    </div>

    <job-form action_type="update" />
  </div>

  <loading v-if="loading" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import JobForm from "@/components/job/JobForm.vue";
import { mdiArrowLeftThin } from "@mdi/js";
import Icon from "@/components/Icon.vue";

const store = useStore();
const route = useRoute();
const icon = ref({ mdiArrowLeftThin });

const role = (detail) => detail.role_title || detail.job.title_name;

const createJobLink = (job) => {
  const company_name = job.employer.name.toLowerCase().replaceAll(' ', '-');
  const title = role(job)?.toLowerCase().replaceAll(' ', '-');
  const job_link = `/${company_name}/${job.fid}/${title}`;
  return job_link;
};

onMounted(async () => {
  let Job = await store.getters['global/getJobWithUuid'](route.params.id);
  if (!Job) {
    //might be a first site visit for the browser
    await store.dispatch('global/getAllJobs');
    Job = await store.getters['global/getJobWithUuid'](route.params.id);
  }
  process.env.NODE_ENV === 'development' ? console.log(Job) : '';
});
</script>

<style lang="css"></style>

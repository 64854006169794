<template>
  <div class="my-9 p-4 md:p-9 bg-white rounded w-full">
    <div class="flex flex-col justify-between items-center">
      <div class="bg-brand-primary rounded-full p-3">
        <img :src="DangerAlert" alt="alert" width="37" height="37" />
      </div>
      <div class="pt-4 flex flex-col justify-between items-center">
        <h1 class="font-cooper font-medium text-3xl text-[#090E11] text-center">Pay ${{ amount }}!</h1>
        <span class="font-GTWalsheimPro font-normal text-sm text-[#090E11]/80 text-center">Proceed with payment confirmation</span>
      </div>
      <div class="pt-4 mb-2">
        <div id="link-authentication-element" />
        <div id="payment-element" />

        <div class="mt-4 flex items-center justify-between">
          <button
            @click="handleSubmit"
            :disabled="isLoading"
            class="bg-brand-primary text-white p-4 rounded-lg mr-3"
            id="payment-button"
            :data-secret="payload.client_secret"
          >
            Confirm Payment
          </button>
          <button
            data-modal-hide="popup-modal"
            type="button"
            @click.prevent="emits('cancel')"
            class="flex-1 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-4 hover:text-gray-900 focus:z-10"
          >
            No, cancel
          </button>
        </div>
      </div>
      <sr-messages :messages="messages" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import { DangerAlert } from '@/icons.js';
import SrMessages from './SrMessages.vue';

const isLoading = ref(false);
const messages = ref([]);
let stripe;
let elements;

const props = defineProps({
  payload: { type: Object, default: () => ({ public_key: '', client_secret: '', email: '' }) },
  amount: { type: Number },
});

const emits = defineEmits(['cancel', 'failed-payment', 'success-payment']);

const onSuccess = (data) => {
  process.env.NODE_ENV === 'development' ? console.log(data) : '';
  emits('success-payment', data);
};

const onFailed = (data) => {
  process.env.NODE_ENV === 'development' ? console.log(data) : '';
  emits('failed-payment', data);
};

const makePayment = async () => {
  isLoading.value = true;
  stripe = await loadStripe(props.payload.public_key);
  elements = stripe.elements({ clientSecret: props.payload.client_secret });
  const paymentElement = elements.create('payment');
  paymentElement.mount('#payment-element');
  const linkAuthenticationElement = elements.create('linkAuthentication');
  linkAuthenticationElement.mount('#link-authentication-element');
  isLoading.value = false;
};

onMounted(async () => {
  await makePayment();
});

const handleSubmit = async () => {
  if (isLoading.value) return;

  isLoading.value = true;

  const { error } = await stripe.confirmPayment({
    elements,
    confirmParams: {
      return_url: `${window.location.origin}#2`,
      receipt_email: props.payload.email,
    },
  });

  if (error === undefined) {
    onSuccess(props.payload);
  } else if (error.type === 'card_error' || error.type === 'validation_error') {
    messages.value.push(error.message);
    onFailed({ ...error, ...{ status: 'failed' } });
  } else {
    messages.value.push('An unexpected error occured.');
    onFailed({ ...error, ...{ message: 'An unexpected error occured.', status: 'failed' } });
  }

  isLoading.value = false;
};
</script>
